<template>
    <div class="loginbg">
        <div>
            <div class="loginpadbghead"></div>
            <div class="loginpadbg"></div>
            <div class="logintitle">
                <div class="logintitle-sitename">{{ $langsmgr("langkey.sitename") }}</div>
                <div class="logintitle-sitenameshort">{{ $langsmgr("langkey.sitesubtitle") }}</div>
            </div>
        </div>
        <div class="loginpad">
            <div class="loginform" :class="ismobile?'widthifmobile':'widthifpc'">
                <div class="padtitle noselect">{{ $langsmgr("langkey.userregistry") }}</div>
                <a-divider></a-divider>
                <div class="loginarea">
                    <div class="loginarea-line pt20px">
                    </div>
                    <div class="loginarea-line">
                        <a-input ref="uemailinput" v-model:value="useremail" maxlength="38" size="large" :placeholder="$langsmgr('langkey.component.register.uemail.holder')" :disabled="isnotcheck">
                            <template #addonAfter>
                                <a-select style="width:140px" v-model:value="defaultemailfrom">
                                    <a-select-option value="@gmail.com">@gmail.com</a-select-option>
                                    <a-select-option value="@icloud.com">@icloud.com</a-select-option>
                                    <a-select-option value="@qq.com">@qq.com</a-select-option>
                                    <a-select-option value="@163.com">@163.com</a-select-option>
                                    <a-select-option value="@126.com">@126.com</a-select-option>
                                    <a-select-option value="@outlook.com">@outlook.com</a-select-option>
                                    <a-select-option value="@hotmail.com">@hotmail.com</a-select-option>
                                </a-select>
                            </template>
                        </a-input>
                    </div>
                    <div class="loginarea-line pt10px">
                        <a-input-password v-model:value="userregpasswd" size="large" :disabled="isnotcheck" :placeholder="$langsmgr('langkey.component.login.passwd.placeholder')">
                            <template #prefix>
                                <edit-outlined/>
                            </template>
                        </a-input-password>
                    </div>
                    <div class="loginarea-line pt10px">
                        <a-input-password v-model:value="userverifypasswd" size="large" :disabled="isnotcheck" :placeholder="$langsmgr('langkey.component.register.vpasswd.holder')">
                            <template #prefix>
                                <edit-outlined/>
                            </template>
                        </a-input-password>
                    </div>
                    <div class="loginarea-line pt10px">
                        <a-input :disabled="isnotcheck||popucodelock" size="large" v-model:value="popucode" :placeholder="$langsmgr('langkey.component.register.promocode.holder')">
                            <template #prefix>
                                <UserAddOutlined/>
                            </template>
                        </a-input>
                    </div>
                    <div class="loginarea-line">
                        <span class="recaptchacheck">
                            <!-- <CheckOutlined v-if="!isnotcheck" class="checkoutlined"/>
                            <a-button :type="recaptchatype" size="small" :loading="isrecaptchachecking" :disabled="!isnotcheck" @click="recaptcha">{{recaptchatext}}</a-button> -->
                            
                            <a-checkbox v-model:checked="tosagreed">
                                &nbsp;
                                {{$langsmgr('langkey.component.register.tosagree')}}
                                &nbsp;
                                <a href="tos.html" target="_blank">{{$langsmgr('langkey.component.register.toscontext')}}</a>
                            </a-checkbox>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                    <div class="loginarea-line pt10px">
                        <a-button type="primary" size="large" :block="false" :loading="loading_registersubmit" :disabled="isnotcheck" 
                            @click="register">
                            <FormOutlined/>
                            {{$langsmgr('langkey.component.register.btntext')}}
                        </a-button>
                    </div>
                </div>
                <CPRArea type="reg"/>
            </div>
        </div>
    </div>
</template>
<script setup>
import { LoadingOutlined,UserAddOutlined,EditOutlined,CheckOutlined,FormOutlined } from '@ant-design/icons-vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { getCurrentInstance, ref, nextTick, onMounted } from 'vue'
import axios from 'axios'
import { notification,message } from 'ant-design-vue'
import Translate from './translate.vue'
import CPRArea from './cprarea.vue'

const uemailinput = ref(null)

const username = ref("")
const useremail = ref('')
const state = ref(false)
const isnotcheck = ref(false)
const isloginning = ref(false)
const isrecaptchachecking = ref(false)
const recaptchatype = ref('primary')
const logintext = ref(null)
const recaptchatext = ref(null)
const defaultemailfrom = ref('@gmail.com')
const userregpasswd = ref('')
const userverifypasswd = ref('')
const tosagreed = ref(true)

const popucode = ref('')
const popucodelock = ref(false)
const gtoken = ref('')

const loading_registersubmit = ref(false)

var __temp_sign = null
        
const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()
const __instance = getCurrentInstance()
const gconfig = __instance.appContext.config.globalProperties
const ismobile = ref(__instance.appContext.config.globalProperties.$ismobile())

logintext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.submit.beforeverify")
recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.normal")

onMounted(()=>{
    axios.get(`${gconfig.$backendbase}/global/v4/fingerprint`,{
        params: {r:Math.random()}
    });
    var __popucode = gconfig.$querystring("p")
    if(__popucode&&__popucode.length>0x00){
        popucode.value = __popucode
        popucodelock.value = true
    }

})

const recaptcha = async() => {
    isrecaptchachecking.value = true;
    recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.working")
    var token = null;
    try{
        await recaptchaLoaded()
        token = await executeRecaptcha('login')
        //console.log(token);
    } catch(e) {
        //console.log("async error.")
    }
    isrecaptchachecking.value = false;
    if(token) {
        axios.get(`${gconfig.$backendbase}/usrctl/v3/checkgtoken`, {
                params: {
                    gtoken: encodeURIComponent(token),
                    r: Math.random()
                }
            }).then((response)=>{
                if(response.data.data.result){
                    isrecaptchachecking.value = false;
                    recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.success")
                    logintext.value = gconfig.$langsmgr('langkey.component.login.signup')
                    recaptchatype.value = "text"
                    isnotcheck.value = false

                    gtoken.value = token
                    __temp_sign = response.data.data.sign

                    nextTick(()=>{
                        uemailinput.value.focus();
                    })
                }else{
                    isrecaptchachecking.value = false;
                    recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.failed")
                }
            }).catch((error)=>{
                isrecaptchachecking.value = false;
                recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.failed")
                if(error.code == "ERR_NETWORK")
                    notification['error']({
                        message: gconfig.$langsmgr('langkey.sysmsg.unknowerror'),
                        description: gconfig.$langsmgr('langkey.sysmsg.checkandcontact'),
                        duration: 3
                    })
            })
    } else {
        recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.failed")
    }
}

const login = ()=>{
    isloginning.value = true;
    logintext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.submit.working")
    // axios.get("https://google.com")
    //     .then((response)=>{
    //         console.log(response);
    //     })
    //     .catch((error)=>{
    //         console.log(error);
    //     })
    if(true)
        setTimeout(()=>{location.href="#/dashboard"},1500)
    else {
        setTimeout(()=>{
            notification['error']({
                message: __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.result.failed.message"),
                description: __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.result.failed.unknowusernameorpassworderror"),
                duration: 3
            })
            isloginning.value = false;
            logintext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.submit.normal")
        },1500)
    }
}
const register = () => {
    if(!tosagreed.value){
        message.warning(gconfig.$langsmgr('langkey.component.register.toswarning'))
        return
    }
    if(!useremail.value||useremail.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.register.valitemsg.emailempty'))
        return
    }
    if(!userregpasswd.value||userregpasswd.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.register.valitemsg.passwdempty'))
        return
    }
    if(userregpasswd.value!=userverifypasswd.value){
        message.error(gconfig.$langsmgr('langkey.component.register.valitemsg.twicepasswdnotmatch'))
        return
    }
    loading_registersubmit.value=true
    axios.post(`${gconfig.$backendbase}/usrctl/v3/signup`,{
        signname: `${useremail.value}${defaultemailfrom.value}`,
        passwd: userregpasswd.value,
        popucode: popucode.value,
        alid: gconfig.$querystring("a"),
        gtoken: gtoken.value,
        sign: __temp_sign
    },{
        params: {r:Math.random()},
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result){
            notification['success']({
                message: gconfig.$langsmgr('langkey.component.register.regmsg.success'),
                description: gconfig.$langsmgr('langkey.component.register.regmsg.success.desc'),
                duration: 3
            })
            location.href="#/login"
        }else{
            switch(resp.data.data.code){
                case "rsc/20001":
                    notification['error']({
                        message: gconfig.$langsmgr('langkey.component.register.regmsg.fail'),
                        description: gconfig.$langsmgr('langkey.component.register.regmsg.fail.mailexisted'),
                        duration: 3
                    })
                    break;
                default:
                    notification['error']({
                        message: gconfig.$langsmgr('langkey.component.register.regmsg.fail'),
                        description: gconfig.$langsmgr('langkey.component.register.regmsg.fail.unknowerror'),
                        duration: 3
                    })
                    break;
            }
        }
        loading_registersubmit.value=false
    }).catch(err=>{
        notification['error']({
            message: gconfig.$langsmgr('langkey.component.register.regmsg.fail'),
            description: gconfig.$langsmgr('langkey.component.register.regmsg.fail.unknowerror'),
            duration: 3
        })
        loading_registersubmit.value=false
    })
}


</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>


</style>